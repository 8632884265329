import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User/Desktop/SignPenFreeWorking/SignPenFree/docs/public/node_modules/gatsby-theme-docz/src/base/Layout.js";
import './index.css';
import BlockQuote from './components/BlockQuote';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "signpenfree-rest-api-reference"
    }}>{`SignPenFree REST API Reference`}</h1>
    <br />
    <hr></hr>
    <h2 {...{
      "id": "base-url"
    }}>{`Base URL`}</h2>
    <p>{`The base url for the production REST API is:`}</p>
    <BlockQuote mdxType="BlockQuote">
      <span style={{
        color: "#0B5FFF"
      }}>https://spf-api.com/api/v2</span>
    </BlockQuote>
    <hr></hr>
    <br />
    <hr></hr>
    <h2 {...{
      "id": "authorize-requests"
    }}>{`Authorize Requests`}</h2>
    <p>{`All requests made to the REST API require valid authentication.`}{` `}{` `}{`When an operation is performed, it will be attributed to the SignPenFree account of the authenticated user.`}</p>
    <p>{`HTTP requests made to the REST API can be authorized in one of two ways:`}</p>
    <ul>
      <li parentName="ul">{`passing an API Key as a query parameter `}</li>
      <li parentName="ul">{`sending an Access Token in the authorization header`}</li>
    </ul>
    <br />
    <p>{`An API Key is a simple encrypted string that identifies an account to SignPenFree. `}</p>
    <p>{`You can pass your API Key into an API call as a query parameter with the following format.`}{` `}{` `}{`Replace API_KEY with your API Key,`}</p>
    <BlockQuote mdxType="BlockQuote">
key=<i><b>API_KEY</b></i>
    </BlockQuote>
    <hr></hr>
    <p>{`For example, to pass an API Key to the REST API for a List Packets request:`}</p>
    <BlockQuote mdxType="BlockQuote">
GET https://spf-api.com/v2/packets/list?key=<i><b>API_KEY</b></i>
    </BlockQuote>
    <hr></hr>
    <br />
    <p>{`An Access Token is a string that identifies an account to SignPenFree.`}{` `}{` `}{`Access Tokens can used to grant an application access to specific REST API resources and methods.`}</p>
    <p>{`You can send an Access Token in the authorization header with the following format. Replace ACCESS_TOKEN with your Access Token,`}</p>
    <BlockQuote mdxType="BlockQuote">
Authorization: Bearer <i><b>ACCESS_TOKEN</b></i>
    </BlockQuote>
    <hr></hr>
    <p>{`For example, to send an Access Token to the REST API for a List Packets request:`}</p>
    <BlockQuote mdxType="BlockQuote">
      {`    `}$ curl --request GET \<br />
      {`    `}--url 'https://spf-api.com/v2/packets/list' \<br />
      {`    `}--header 'Authorization: Bearer <i><b>ACCESS_TOKEN</b></i>'
    </BlockQuote>
    <hr></hr>
    <br />
    <p>{`An API Key is assigned to your account after it is created.`}</p>
    <p>{`You can create new Access Tokens, and regenerate or delete them at any time.`}</p>
    <br />
    <hr></hr>
    <h2 {...{
      "id": "formatting-requests"
    }}>{`Formatting Requests`}</h2>
    <p>{`Standard list format includes the following parameters:`}</p>
    <p>{`When querying for lists, use the following standard query parameters: `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Limit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects requested by the api caller`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Offset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The item number to start the request with`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The list of objects within the Limit and Offset criteria response`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`total`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The  number of objects returned in the request`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The Offset parameter allows for pagination of Results enabling larger Result sets to be fetched.`}</p>
    <p>{`For an account with 201 packets,`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{` `}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Request URI`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Result`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1st request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/api/v2/packets/list?`}<em parentName="td">{`Limit`}</em>{`=100&`}<em parentName="td">{`Offset`}</em>{`=0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 packets returned`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2nd request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/api/v2/packets/list?`}<em parentName="td">{`Limit`}</em>{`=100&`}<em parentName="td">{`Offset`}</em>{`=10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 packets returned`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3rd request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`/api/v2/packets/list?`}<em parentName="td">{`Limit`}</em>{`=100&`}<em parentName="td">{`Offset`}</em>{`=20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 packet returned`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <br />
    <hr></hr>
    <h2 {...{
      "id": "error-responses"
    }}>{`Error Responses`}</h2>
    <p>{`This section identifies some of the error codes and messages that the SignPenFree API returns.`}</p>
    <p>{`The errors below are listed by their HTTP status codes as defined in `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7231#section-6"
      }}>{`RFC 7231`}</a>{`.`}</p>
    <p>{`The JSON response below demonstrates how an error is communicated:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "error": {
    "errors": [
      {
        "domain": "global",
        "reason": "authError",
        "message": "Invalid Credentials",
        "locationType": "header",
        "location": "Authorization"
      }
    ],
    "code": 401,
    "message": "Invalid Credentials"
  }
}
`}</code></pre>
    <hr></hr>
    <BlockQuote color="red" mdxType="BlockQuote">
If you receive an error, you should: <br />
      {`    `}1. Immediately log the response. <br />
      {`    `}2. Investigate to determine the root cause. <br />
      {`    `}3. Update your application to eliminate the error.
    </BlockQuote>
    <hr></hr>
    <h3 {...{
      "id": "errors"
    }}>{`Errors`}</h3>
    <h4 {...{
      "id": "bad_request-400"
    }}>{`BAD_REQUEST (400)`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`badRequest`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API request is invalid or improperly formed. Consequently, the API server could not understand the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`invalid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request failed because it contained an invalid value. The value could be a parameter value, a header value, or a property value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`invalidHeader`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request failed because it contained an invalid header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`invalidParameter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request failed because it contained an invalid parameter or parameter value. Review the API documentation to determine which parameters are valid for your request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`invalidQuery`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request is invalid. Check the API documentation to determine what parameters are supported for the request and to see if the request contains an invalid combination of parameters or an invalid parameter value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`keyExpired`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API key provided in the request expired, which means the API server is unable to check the quota Limit for the application making the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`keyInvalid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API key provided in the request is invalid, which means the API server is unable to check the quota Limit for the application making the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`parseError`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API server cannot parse the request body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API request is missing required information. The required information could be a parameter or resource property.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`unknownApi`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API that the request is calling is not recognized.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "unauthorized-401"
    }}>{`UNAUTHORIZED (401)`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user is not authorized to make the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`authError`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The authorization credentials provided for the request are invalid. Check the value of the Authorization HTTP request header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`expired`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Session Expired. Check the value of the Authorization HTTP request header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user must be logged in to make this API request. Check the value of the Authorization HTTP request header.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "payment_required-402"
    }}>{`PAYMENT_REQUIRED (402)`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dailyLimitExceeded402`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A daily budget Limit set by the developer has been reached.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`quotaExceeded402`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested operation requires more resources than the quota allows. Payment is required to complete the operation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`account402`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested operation requires some kind of payment from the authenticated account.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "forbidden-403"
    }}>{`FORBIDDEN (403)`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`accountDisabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user account associated with the request's authorization credentials has been disabled.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "not_found-404"
    }}>{`NOT_FOUND (404)`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`notFound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested operation failed because a resource associated with the request could not be found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`unsupportedProtocol`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The protocol used in the request is not supported.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "method_not_allowed-405"
    }}>{`METHOD_NOT_ALLOWED (405)`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`httpMethodNotAllowed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP method associated with the request is not supported.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "gone-410"
    }}>{`GONE (410)`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`deleted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request failed because the resource associated with the request has been deleted.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "too_many_requests-429"
    }}>{`TOO_MANY_REQUESTS (429)`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Error Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rateLimitExceeded`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests have been sent within a given time span.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      